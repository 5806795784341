import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "react-fullscreen-loading";
import axios from "axios";

const Dashboard = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [detailsToShow, setDetailsToShow] = useState({});

  const user = useSelector((state) => state.user.currentUser);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    const token = localStorage.getItem("cathay-dashboard");

    const getTransactions = async () => {
      const { data } = await axios.get(
        `https://app.cathaynational.com/api/transaction/${user?._id}`,
        {
          headers: { token: `Bearer ${token}` },
        }
      );

      setTransactions(
        data.sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime))
      );

      setTimeout(() => {
        setPageLoading(false);
      }, 1000);
    };

    getTransactions();
  }, [user?._id]);

  return (
    <div id="main-wrapper">
      <Header />
      <div id="content" className="py-4">
        <div className="container">
          <div className="row">
            <Sidebar activePage="Dashboard" />
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-12">
                  <div className="bg-light shadow-sm text-center mb-3">
                    <div className="d-flex admin-heading pr-3">
                      <span className="mr-auto">
                        <h3 className="text-5 font-weight-400">
                          <i className="fas fa-wallet" /> Available Balance
                        </h3>
                      </span>
                      <span className="ml-auto">
                        <h3 className="text-5 font-weight-500">
                          ${user?.balance?.toLocaleString()}
                        </h3>
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-light shadow-sm rounded profile-content">
                <h3 className="text-5 font-weight-700 d-flex admin-heading">
                  Recent Activity
                </h3>
                {/* Title  */}
                <div className="transaction-title py-2 px-4">
                  <div className="row">
                    <div className="col-2 col-sm-1 text-center">
                      <span className>Date</span>
                    </div>
                    <div className="col col-sm-7">Description</div>
                    <div className="col-auto col-sm-2 d-none d-sm-block text-center">
                      Status
                    </div>
                    <div className="col-3 col-sm-2 text-right">Amount</div>
                  </div>
                </div>
                {/* Title End */}
                {/* Transaction List */}
                {transactions?.length > 0 ? (
                  <>
                    {transactions?.slice(0, 3)?.map((transaction) => (
                      <div
                        className="transaction-list"
                        key={transaction._id}
                        onClick={() => {
                          setDetailsToShow(transaction);
                        }}
                      >
                        <div
                          className="transaction-item px-4 py-3"
                          data-toggle="modal"
                          data-target="#transaction-detail"
                        >
                          <div className="row align-items-center flex-row">
                            <div className="col-3 col-sm-1 text-center pay-date">
                              <span className="text-4 font-weight-300">
                                {new Date(transaction?.dateTime).getDate()}
                              </span>
                              <span className="text-1 font-weight-300 text-uppercase pay-month">
                                {`${
                                  monthNames[
                                    new Date(transaction?.dateTime).getMonth()
                                  ]
                                }`}
                              </span>
                            </div>
                            <div className="col col-sm-7">
                              {transaction.type === "Withdrawal" ? (
                                <span className="text-4">Money Sent</span>
                              ) : (
                                <span className="text-4">Money Received</span>
                              )}
                            </div>
                            <div className="col-auto col-sm-2 d-none d-sm-block text-center text-3">
                              {transaction.status === "Unconfirmed" && (
                                <span
                                  className="text-warning"
                                  data-toggle="tooltip"
                                  data-original-title="In Progress"
                                >
                                  <i className="fas fa-ellipsis-h" />
                                </span>
                              )}
                              {transaction.status === "Pending" && (
                                <span
                                  className="text-warning"
                                  data-toggle="tooltip"
                                  data-original-title="In Progress"
                                >
                                  <i className="fas fa-ellipsis-h" />
                                </span>
                              )}
                              {transaction.status === "Completed" && (
                                <span
                                  className="text-success"
                                  data-toggle="tooltip"
                                  data-original-title="Completed"
                                >
                                  <i className="fas fa-check-circle"></i>
                                </span>
                              )}
                              {transaction.status === "Cancelled" && (
                                <span
                                  className="text-danger"
                                  data-toggle="tooltip"
                                  data-original-title="Cancelled"
                                >
                                  <i className="fas fa-times-circle"></i>
                                </span>
                              )}
                            </div>
                            <div className="col-3 col-sm-2 text-right text-4">
                              <span className="text-nowrap">
                                {transaction.type === "Withdrawal" ? "-" : "+"}{" "}
                                ${transaction?.amount?.toLocaleString()}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text-center mt-3 py-4">
                    <p>No transactions yet.</p>
                  </div>
                )}

                <div
                  id="transaction-detail"
                  className="modal fade"
                  role="dialog"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered transaction-details"
                    role="document"
                  >
                    <div className="modal-content">
                      <div className="modal-body">
                        <div className="row no-gutters">
                          <div className="col-sm-12 bg-primary rounded-left">
                            <div className="col-md-6 mt-5">
                              <div className="text-8 font-weight-500 text-white">
                                ${detailsToShow?.amount?.toLocaleString()}
                              </div>
                              <p className="text-white">
                                {new Date(
                                  detailsToShow?.dateTime
                                ).toLocaleString()}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <h5 className="text-5 font-weight-400 m-3">
                              Transaction Details
                              <button
                                type="button"
                                className="close font-weight-400"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </h5>
                            <hr />
                            <div className="px-3">
                              <ul className="list-unstyled">
                                <li className="mb-2">
                                  Transaction Type
                                  <span className="float-right text-3">
                                    {detailsToShow.type}
                                  </span>
                                </li>
                              </ul>
                              <hr className="mb-2" />

                              <ul className="list-unstyled">
                                <li className="font-weight-500">
                                  Transaction ID:
                                </li>
                                <li className="text-muted">
                                  {detailsToShow?.transactionId}
                                </li>
                              </ul>
                              {detailsToShow?.bankDetails?.accountName && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Account Name:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.accountName}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.accountNumber && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Account Number:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.accountNumber}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.accountType && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Account Type:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.accountType}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.bankName && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Bank Name:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.bankName}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.country && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">Country:</li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.country}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.routingNumber && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Routing Number:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.routingNumber}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.bankDetails?.swiftCode && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Swift Code:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.bankDetails?.swiftCode}
                                  </li>
                                </ul>
                              )}
                              {detailsToShow?.description && (
                                <ul className="list-unstyled">
                                  <li className="font-weight-500">
                                    Description:
                                  </li>
                                  <li className="text-muted">
                                    {detailsToShow?.description}
                                  </li>
                                </ul>
                              )}
                              <ul className="list-unstyled">
                                <li className="font-weight-500">Status:</li>
                                <li className="text-muted">
                                  {detailsToShow?.status}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {transactions?.length > 0 && (
                  <div className="text-center mt-3 py-4">
                    <Link to="/transactions" className="btn-link text-3">
                      View all
                      <i className="fas fa-chevron-right text-2 ml-2" />
                    </Link>
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {pageLoading && (
        <Loading loading background="#ffffff" loaderColor="#123d88" />
      )}
    </div>
  );
};

export default Dashboard;
